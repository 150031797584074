.contentsTitGroup {margin-bottom:30px; position:relative;}
.contentsTitGroup .title {height:40px;}
.contentsTitGroup .title > strong {display:block; height: 40px; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px; color: #000000;}
.contentsTitGroup .pagenation {/*overflow:hidden;*/ display:inline-flex; align-items:center; gap:1px; /*width:81px;*/ height:40px; background-color:#eee; position:absolute; right:0; top:0; /*border:1px solid #eee;*/ border-radius:2px; box-sizing:border-box;}
/*.contentsTitGroup .pagenation:after {content:""; display:block; clear:both;}*/
.contentsTitGroup .pagenation .btnPrev,
.contentsTitGroup .pagenation .btnNext {width:40px; height:40px; font-size:0; background-color:#fff; background-repeat:no-repeat; background-position:center center; background-size:24px auto; border-style:solid; border-color:#eee; box-sizing:border-box;}
.contentsTitGroup .pagenation .btnPrev { background-image:url(/images/icon/arrow_prev.svg); border-width:1px 0 1px 1px; border-radius:2px 0 0 2px;}
.contentsTitGroup .pagenation .btnNext {background-image:url(/images/icon/arrow_next.svg); border-width:1px 1px 1px 0; border-radius:0 2px 2px 0;}
.contentsContGroup {}
.contentsContGroup .swiper-container {}
.swiper-container {}
.swiper-container .swiper-wrapper {}
.swiper-container .swiper-slide {min-width:212px;}
.swiper-container .swiper-slide:last-child {margin-right:0;}

@media screen and (min-width: 100px) and (max-width: 1279px) {
    /* .contentsTitGroup .title > h3 {padding:0 16px;} */
    /* .contentsTitGroup .pagenation {right:16px;} */
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .contentsTitGroup {
        margin-bottom:16px;
        /* padding:0 20px; */
    }
    .contentsTitGroup .title {height:32px;}
    .contentsTitGroup .title > h3 {height: 32px; padding:0; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px;}
    .contentsTitGroup .pagenation {display:none;}
    .swiper-container {padding-left:20px;}
    .swiper-container .swiper-slide {width:212px !important;}
}