
/* 메인비주얼 틀 */
.lytMainVisual {width:100%;}
.lytMainVisual .ir {visibility:hidden; position:absolute; left:-9999em; top:-9999em; font-size:9px;}
.lytMainVisual .visualArea {position:relative;}
.lytMainVisual .visualList {height:584px;}

/* 메인 비주얼 로케이션 메뉴 */
.lytMainVisual .visualLocation {width:100%; position:absolute; left:0; top:0;}
.lytMainVisual .visualLocationArea {width:100%; max-width: 1512px; margin:0 auto; position:relative;}
.lytMainVisual .visualLocationBox {width:140px; height:128px; position:absolute; right:66px; top:59px; z-index: 1;}
.lytMainVisual .visualLocationBox .locationArea {height:100%; /*-webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 7%, black 70%, transparent 100%);*/}
.lytMainVisual .visualLocationBox {}
.lytMainVisual .visualLocationBox .locaList {height: 32px; text-align:right;}
.lytMainVisual .visualLocationBox .locaList.on button:after {content:""; width:16px; height:16px; background:url(/images/icon/ico-16-arrow-right-2.svg) center no-repeat; position:absolute; left:-24px; top:8px; display:block;}
.lytMainVisual .visualLocationBox .locaList button {height: 32px; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px; text-align: center; color: #ffffff; position:relative;}
.lytMainVisual .bannerContentsGroup {width:100%; height:100%; position:relative;}
.lytMainVisual .bannerContentsGroup .bannerBg {width:100%; height:584px; text-align:center; position:relative; overflow:hidden; background-color: #010101;}
.lytMainVisual .bannerContentsGroup .bannerImage {position:absolute; right:50%; transform:translateX(50%); width:1920px; height:100%;}
.lytMainVisual .bannerContentsGroup .bannerImage a:focus-visible span:after {position:absolute; top:0; left:16px; display:block; width:calc(100vw - 32px); height:calc(100% - 24px); margin:10px auto; border:2px solid #3c89f9; content:''; z-index:3;}

.controlWrap {
    display:flex;
    align-items:center;
    justify-content:center;
    gap:32px;
    position:absolute;
    left:0;
    bottom:32px;
    width:100%;
    z-index:10;
}
.controlButton {
    display:flex;
    align-items:center;
    /*
    position: absolute;
    top: auto;
    bottom: 20px;
    right: 40px;
    padding: 10px 10px;
    */
    padding:0 10px;
    color: #fff;
    border: none;
    cursor: pointer;
    z-index: 50;
    display: flex;
    align-items: center;
    /*
    background: #444444;
    */
    background-color:rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    font-size: 16px;
    line-height:1.5;
}

.controlButton button {
    display:flex;
    align-items:center;
    justify-content:center;
    width:24px;
    height:24px;

}

.controlButton img {
    width: 100%;
    filter:invert(100%) sepia(0%) saturate(6407%) hue-rotate(43deg) brightness(124%) contrast(112%);
}
.controlButton .paginationNum {
    margin: 0 24px 0 10px;
}
.controlButton [class*="paginationButton"] {

    background-repeat:no-repeat;
    background-position:center center;
    background-size:100% auto;
    /*
    margin: 0px 10px;
    font-size: 16px;
    font-weight: bold;
    */
    filter:invert(100%) sepia(0%) saturate(6407%) hue-rotate(43deg) brightness(124%) contrast(112%);
}
.controlButton .paginationButtonPrev {
    background-image:url('/images/icon/arrow_prev.svg');
}
.controlButton .paginationButtonNext {
    background-image:url('/images/icon/arrow_next.svg');
}
.controlButton button [class*="btnIcon"] {
    display:block;
    width:24px;
    height:24px;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:100% auto;

}
.controlButton .buttonPlayStop {
    margin: 0px 10px;
}
.controlButton button .btnIconPause {
    background-image: url("/images/icon/ico_pause.svg");
    filter:invert(100%) sepia(0%) saturate(6407%) hue-rotate(43deg) brightness(124%) contrast(112%);
}
.controlButton button .btnIconPlay {
    background-image: url("/images/icon/iconPlayWhite.svg");
}

.autoProgress {
    overflow:hidden;
    display:inline-block;
    padding:4px;
    width:100%;
    max-width:440px;
    height:16px;
    background-color:#F6F6F6;
    border-radius:100px;
    font-size:0;
    line-height:0;
    box-sizing:border-box;
}

.autoProgress .progressBar {
    display:inline-block;
    width: 0%;
    height: 8px;
    background-color: #21B9FF;
    border-radius:100px;
    transition: width 5s linear;
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytMainVisual .visualList {height:460px;}
    .lytMainVisual .visualList.userLogin { height: 4885px;}
    .lytMainVisual .visualLocation {width:100%; position:absolute; left:0; top:0;}
    .lytMainVisual .visualLocationArea {width:100%; max-width:none; margin:0;}
    .lytMainVisual .visualLocationBox {width:100%; height:46px; padding:5px 20px 0; right:0; top:0; z-index:10;}
    .lytMainVisual .visualLocationBox .locationArea {height:100%; -webkit-mask-image:unset;  border-bottom:1px solid rgba(255,255,255,0.2);}
    .lytMainVisual .visualLocationBox .locaGroup li {width:auto; height: 45px; padding:0 5px; text-align:left; display:inline-block;}
    .lytMainVisual .visualLocationBox .locaGroup li.on button:after {display:none;}
    .lytMainVisual .visualLocationBox .locaGroup li button {height: 45px; font-size: 14px; font-weight: bold; line-height: normal; letter-spacing: -0.29px; border-bottom:2px solid rgba(255,255,255,0);}
    .lytMainVisual .visualLocationBox .locaGroup li.on button {border-bottom:2px solid rgba(255,255,255,1);}

    .controlWrap {
        gap:14px;
        bottom:10px;
        padding:0 20px;
        box-sizing:border-box;
    }
    .controlButton {
        /*
        position: absolute;
        top: auto;
        bottom: 10px;
        right: 10px;
        padding: 7px 5px;
        */
        color: #fff;
        border: none;
        cursor: pointer;
        z-index: 90;
        display: flex;
        align-items: center;
        /*
        background: #444444;
        */
        border-radius: 30px;
        font-size: 12px;
        white-space:nowrap;
    }

    .controlButton button {
        font-size:10px;
    }
    /*
    .controlButton img {
        width: 12px;
    }
    */
    .controlButton .paginationNum {
        margin: 0 10px 0 0;
        font-size: 12px;
    }
    .controlButton [class*="paginationButton"] {
        margin: 0;
        /*
        font-size: 10px;
        font-weight: bold;
        */
    }

    .autoProgress {
        padding:3px;
        height:12px;
    }

    .autoProgress .progressBar {
        height:6px;
    }
}

@media screen and (min-width: 100px) and (max-width: 1435px) {
    .lytMainVisual .bannerContentsGroup .bannerImage {left:-242.5px; right:auto; transform:translateX(0);}
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytMainVisual .bannerContentsGroup .bannerBg {height:100%;}
    .lytMainVisual .bannerContentsGroup .bannerImage {position:absolute; left:0; width:100%; height:100%; display:flex; justify-content:center;}
    .lytMainVisual .bannerContentsGroup .bannerImage img {max-width: unset !important;
        min-width: unset !important;
        min-height: unset !important;
        max-height: unset !important;
        width: 100% !important;
        height: auto !important;}
}

@media screen and (min-width:100px) and (max-width:344px) {
    .controlButton .paginationNum {
        margin: 0 5px 0 0;
        font-size: 11px;
    }
    .controlButton button {
        width:20px;
        height:20px;
    }
    .controlButton button [class*="btnIcon"] {
        width:20px; height:20px;
    }
    .controlButton .buttonPlayStop {
        margin: 0px 5px;
    }
}
