/* process card item */
.cptThumVsl {
  width: 100%;
  min-width: 212px;
}
.cptThumVsl .thumLink {
  width: 100%;
  height: 100%;
  display: block;
}

.cptThumVsl .status {
  margin-bottom:20px;
  width:100%;
}
.cptThumVsl .status [class*="flag"] {
  display:flex;
  align-items:center;
  justify-content:center;
  gap:4px;
  position:relative;
  padding:6px 8px;
  width:100%;
  height:32px;
  background-color:#fff;
  border-radius:50px;
  box-sizing:border-box;
}
.cptThumVsl .status [class*="flag"]:before {
  content:'';
  position:absolute;
  left:50%;
  bottom:0;
  width:12px;
  height:12px;
  border-radius:2px;
  transform:translate(-50%, 50%) rotate(45deg);
}
.cptThumVsl .status [class*="flag"] .txt {
  font-size:14px;
  font-weight:700;
  line-height:1.5;
}

.cptThumVsl .status [class="flagProgress"],
.cptThumVsl .status [class="flagProgress"]:before {
  background-color:#48C5FF;
}
.cptThumVsl .status [class="flagProgress"] .txt {
  color:#fff;
}

.cptThumVsl .status [class="flagWaiting"],
.cptThumVsl .status [class="flagWaiting"]:before {
  background-color:#F2F2F2;
}
.cptThumVsl .status [class="flagWaiting"] .txt {
  color:#8F8F8F;
}

.cptThumVsl .thumImgGroup {
  overflow:hidden;
  position: relative;
  width: 100%;
  background: #f2f2f2;
  border:2px solid #fff;
  border-radius:8px;
  box-sizing:border-box;
}
.cptThumVsl .thumImgGroup .imgBox {
  overflow:hidden;
  position:relative;
  padding-top:56.27%;
  width: 100%;
  font-size: 0;
}
.cptThumVsl .thumImgGroup .imgBox > img {
  position:absolute;
  left:50%;
  top:50%;
  width: 100%;
  transform:translate(-50%, -50%);
  object-fit:cover;
  object-position:center;
}
.cptThumVsl .thumImgGroup .imgInfo {
  width: 100%;
  height: 40px;
  position: relative;
}
.cptThumVsl .thumImgGroup .imgInfo .badgeBox {
  font-size: 0;
  position: absolute;
  left: 0;
  bottom: 0;
}
.cptThumVsl .thumImgGroup .imgInfo .badgeBlue {
  height: 24px;
  padding: 2px 10px 0 10px;
  font-family: "Rubik";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.16px;
  color: #ffffff;
  vertical-align: top;
  background-color: #3c89f9;
  display: inline-block;
  box-sizing:border-box;
}
.cptThumVsl .thumImgGroup .imgInfo .badgeBlack {
  height: 24px;
  padding: 2px 10px 0 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.16px;
  color: #ffffff;
  vertical-align: top;
  background-color: #000000;
  display: inline-block;
  box-sizing: border-box;
}

.cptThumVsl .thumContGroup {
  width: 100%;
}
.cptThumVsl .thumTitle {
  height: 24px;
  margin-top: 16px;
}
.cptThumVsl .thumTitle strong {
  width: 100%;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: -0.29px;
  color: #ffffff;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 100px) and (max-width: 1279px) {
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
  .cptThumVsl {
    width: 100%;
    min-width: auto;
  }
  .cptThumVsl .thumImgGroup .imgInfo {
    height: 24px;
  }
  .swiper-slide > .cptThumVsl .thumImgGroup .badgeBox {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }
  .swiper-slide > .cptThumVsl .thumContGroup {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }
  .swiper-slide.swiper-slide-active > .cptThumVsl .thumImgGroup .badgeBox {
    visibility: visible;
    opacity: 1;
  }
  .swiper-slide.swiper-slide-active > .cptThumVsl .thumContGroup {
    visibility: visible;
    opacity: 1;
  }

  .cptThumVsl .status [class*="flag"] {
    padding:3px 5px;
    height:auto;
  }
  .cptThumVsl .status [class*="flag"]:before {
    width:8px;
    height:8px;
  }
  .cptThumVsl .status [class*="flag"] .txt {
    font-size:12px;
  }
}

@media screen and (min-width: 100px) and (max-width: 640px) {
  /* 240715 메인 키비주얼 수강/대기 과정 수정 */
  .cptThumVsl .thumImgGroup .imgBox img {
    width: 100% !important;
    /*height: 78px !important;*/
  }
  .cptThumVsl .thumImgGroup .imgInfo {
    height: 22px;
  }

  .cptThumVsl .thumImgGroup .imgInfo .badgeBlue {
    height: 22px;
    font-size: 10px;
  }
  .cptThumVsl .thumImgGroup .imgInfo .badgeBlack {
    height: 22px;
    font-size: 10px;
  }
  .cptThumVsl .thumTitle {
    margin-top: 8px;
  }

  .cptThumVsl .thumTitle strong {
    height: 21px;
    font-size: 12px;
  }
  .cptThumVsl .status {
    margin-bottom:10px;
  }
  .cptThumVsl .status [class*="flag"] {
    padding:3px 5px;
    height:auto;
  }
  .cptThumVsl .status [class*="flag"]:before {
    width:8px;
    height:8px;
  }
  .cptThumVsl .status [class*="flag"] .txt {
    font-size:12px;
  }
}

@media screen and (min-width:100px) and (max-width:344px) {
  .cptThumVsl .thumTitle {
    margin-top:4px;
  }
  cptThumVsl .thumTitle strong {
    height: auto;
    font-size: 12px;
  }
  .cptThumVsl .status [class*="flag"] {
    padding:3px 4px 2px;
    height:auto;
  }
  .cptThumVsl .status [class*="flag"] .txt {
    font-size:11px;
    line-height:1.5;
  }

  .cptThumVsl .thumImgGroup {
    border-width:1px;
  }
}
