.ebbokSliderWrap {margin:80px auto 0; width:100%; max-width:1400px;}

.ebookTitle {
    font-size: 24px;
    font-weight : bold;
    width: 100%;
}

.ebookWrap {
    height: 620px;
}
.ebookWrapMain {
    height: 620px;
    top: 70px;
}


@media screen and (min-width: 100px) and (max-width: 1023px) {
    .ebookTitle {
        display: none;
    }

    .ebookWrap {
        /*height: 390px;*/
        margin-left:-20px;
        width:calc(100% + 20px);
    }
    .ebookWrapMain {
        top: 0px;
        /*height: 390px;*/
    }


}

@media screen and (min-width:100px) and (max-width:767px) {
    .ebbokSliderWrap {margin-top:0;}
}